const appConfig = {
    apiPrefix: 'https://103.191.209.46:5000/api/v1/web/',
    // apiPrefix: 'http://localhost:5000/api/v1/web/',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
